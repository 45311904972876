@use '../../styles/colors';
@use '../../styles/values';

.container {
    display: flex;
    flex-direction: column;

    .title {
        background-color: colors.$brown;
        font-family: values.$fontText;
        text-align: center;
        font-size: 20px;
        min-height: 70px;
        font-weight: 400;
    }

   .tableau {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap:50px;
    justify-content: center;

    .image {
        width: 200px;
        height: auto;
        border-radius: 5px;
        cursor: zoom-in;
        
        img{
            box-shadow: 5px 4px 13px -2px rgb(37, 39, 18);

        }
      }
      

    .name{
        text-align: center;
        font-weight: bold;
    }
    .size{
        text-align: center;
        margin-Top: -15px;
    }
   }

   .text{
    font-family: values.$fontContent;
    font-size: 1.1rem;
    text-align: start;
    padding: 0 15px;
   }

   .textnext {
    font-family: values.$fontContent;
    font-size: 1.1rem;
    text-align: center;
   }

   @media screen and (max-width: 768px) {      
    .tableau {
        margin-top: 30px;
        gap:30px;

        .image {
            max-width: 250px;
            height: auto;
            
          }
    }  
    .content {
        margin:auto;
        .text {
            margin: 0 15px 0 15px;
        }
}
}


@media screen and (max-width: 480px) {
    
}
}