@use '../../styles/colors.scss';
@use '../../styles/values';

.container {
    flex:1;
    background-image: url('../../assets/background_image.jpg');
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
    
    h1
    {
        color: colors.$brownDark;
        font-family: values.$fontText;
        font-weight: 420;
        font-size: 35px;
        margin-left:50px;
        width: 100%;
    }
    
    @media screen and (max-width: 768px) {
       flex-direction: column;
       align-items: flex-start;
       justify-content: start;
       background-position: top;

        h1 {
            font-size: 1.9rem;
            text-align: center;
            margin: auto;
            max-width: 80%;
    }    
    }

    @media screen and (max-width: 480px) {
       
}
}