@use '../styles/values';

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #D9D9D9;
    font-family: values.$fontText;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }