@use '../../styles/colors';
@use '../../styles/values';

.container {
    // margin-top: 10px;
    display: flex;
    flex-direction: column;

    .title {
        background-color: colors.$brown;
        min-height: 70px;

        p {
            font-family: values.$fontText;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
        }

    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;

        .mail {
            text-align: center;
            font-weight: bold;
            font-size: 1.1rem;

            p {
                margin: 0;
            }

        }

        .text {
            font-family: values.$fontContent;
            font-size: 1.1rem;
            text-align: center;
            margin: 0;

            p {
                margin: 0;
            }
        }
    }

    .icons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {

        .contact {
            margin-top: 5px;
            .text {
                margin: 5px 20px 0 20px;
            }
        }

       

    }

    @media screen and (max-width: 480px) {}

}