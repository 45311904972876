@use '../../styles/colors';
@use '../../styles/values';

.container {
    display: flex;
    flex-direction: column;
    color: colors.$brownDark;

    .title {
        font-size: 20px;
        background-color: colors.$green;
        font-family: values.$fontText;
        text-align: center;
        min-height: 70px;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin:0 20px 0 20px;
        font-family: values.$fontContent;
        
        .imageContainer {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 23%;
            
            .image {
                max-width: 80%;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 5px;
                margin-top: -20px;
                margin-bottom: 20px;
                box-shadow: 5px 4px 13px -2px rgba(0,0,0,0.97);
            }
        }
        
        .text {
            flex: 2;
            margin: auto;
            margin-left: 30px;
            font-family: values.$fontContent;
            font-size: 1.1rem;
        }
    }
    @media screen and (max-width: 768px) {        
        .content {
            display: flex;
            flex-direction: column;
            margin:auto;
            min-width: 100%;
            
            .imageContainer {
                min-width: 100%;
                margin-top: 20px;
                .image {
                    width: 100%;
                    margin: auto;
                   
                }
            }
            .text {
                margin: 10px 20px 0 20px;
            }
    }
}
   
}

@media screen and (max-width: 480px) {
    .content {
        display: flex;
        flex-direction: column;

        .imageContainer {
            width: 100%;
            
            .image {
                width: 100%;
               
            }
        }

}
}
